import { store } from '../store/store'

// const isAuthRoute = route => route.path.indexOf('/login') !== -1;
const isprotected = route => {
    if (route.meta && route.meta.auth) {
        return true
    } else {
        return false
    }
}

let entryUrl = null
export default async (to, from, next) => {
  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`
    if (isprotected(to)) {
        if (store.state.login) {
            if (entryUrl) {
                const url = entryUrl
                entryUrl = null
                return next(url) // goto stored url
            } else {
                return next() // all is fine
            }
        }

        await store.commit('setValue', { type: 'loading', value: true })

         axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`).then(async (resp) => {
           await store.commit('setValue', {
             type: 'logging',
             value: true
           })
           // eslint-disable-next-line no-undef
           axios({
             method: 'post',
             url: '/user',
             data: {},
             validateStatus: (status) => {
               return true
             },
           }).catch(error => {
             // alert("Error");
             entryUrl = to.path // store entry url before redirect
             next('/login')
           }).then(async response => {
             if (!response.data.data || response.data.error) {
               entryUrl = to.path // store entry url before redirect
               next('/login')

               return false
             }
             await store.commit('setValue', {
               type: 'logging',
               value: false
             })
             await store.commit('setValue', {
               type: 'login',
               value: true
             })
             await store.commit('setValue', {
               type: 'user',
               value: response.data.data
             })

             next()

             // this is now called!
           })
         })

        // we use await as this async request has to finish
        // before we can be sure
        // const isLogged = () => store.state.login;
    } else {
        next()
    }
}
