import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import { store } from './store/store'
import './plugins'
import { i18n } from '@/plugins/i18n'
import '@/assets/scss/_global.scss'
import './mixins/icemixin'
// plugin svgrender
// import Vue plugin
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'

// import polyfills for IE if you want to support it
import 'vue-svg-inline-plugin/src/polyfills'

// end:: svgrender

import { VueReCaptcha } from 'vue-recaptcha-v3'

import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  import VueApexCharts from 'vue-apexcharts'

import './registerServiceWorker'
Vue.config.productionTip = false
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin)

// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
	attributes: {
		data: ['src'],
		remove: ['alt'],
	},
})

  // For more options see below
  Vue.use(VueReCaptcha, { siteKey: '6Ld8WMkZAAAAAILTd2edjCx-todl8SfYL2PA5BEc' })
  Vue.component(VueSlickCarousel)
  Vue.component('VueSlickCarousel', VueSlickCarousel)
  Vue.use(VueApexCharts)

  Vue.component('apexchart', VueApexCharts)
new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
