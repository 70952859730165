// Imports
import Vue from 'vue'
import Router from 'vue-router'

import beforeEach from './beforeEach'
import afterEach from './afterEach'
import { i18n } from '@/plugins/i18n'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/base/Index.vue'),
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import('@/views/home/Home.vue'),
        },
        {
          path: '/welcome',
          name: 'Welcome Page',
          meta: { auth: true },
          component: () => import('@/views/Welcome.vue'),
        },
        {
          path: '/profile/',
          name: 'Profile Page',
          meta: { auth: true },
          component: () => import('@/layouts/base/Profile.vue'),
          children: [
            {
              path: '/',
              name: 'Profile',
              meta: { auth: true },
              component: () => import('@/views/profile/ViewProfile.vue'),

            },
            {
              path: '/notifications',
              name: 'Notifications',
              meta: { auth: true },
              component: () => import('@/views/Notifications.vue'),
            },
            {
              path: '/wallet',
              name: 'Revenu',
              meta: { auth: true },
              component: () => import('@/views/profile/Wallet.vue'),
            },
            {
              path: '/bank-accounts',
              name: 'Compte banque',
              meta: { auth: true },
              component: () => import('@/views/profile/Bankaccounts.vue'),
            },
            {
              path: '/edit-profile',
              name: 'EditProfile',
              meta: { auth: true },
              component: () => import('@/views/profile/EditProfile.vue'),
            },
            {
              path: '/all-tickets',
              name: 'AllTickets',
              meta: { auth: true },
              component: () => import('@/views/AllTickets.vue'),
            },
            {
              path: '/ticket-details',
              name: 'TicketDetails',
              meta: { auth: true },
              component: () => import('@/views/TicketDetails.vue'),
            },
            {
              path: '/wishlist',
              name: 'Wishlist',
              meta: { auth: true },
              component: () => import('@/views/Wishlist.vue'),
            },
            {
              path: '/reviews',
              name: 'Avis',
              meta: { auth: true },
              component: () => import('@/views/vendor/ReviewList.vue'),
            },
            {
              path: '/transporters',
              name: 'Gestion des Transporteurs',
              meta: { auth: true },
              component: () => import('@/views/vendor/TransporterList.vue'),
            },
            {
              path: '/promotions',
              name: 'Gestion des Promotions',
              meta: { auth: true },
              component: () => import('@/views/vendor/Promotions.vue'),
            },
            {
              path: '/promotionform',
              name: 'Formulaire promotions',
              meta: { auth: true },
              component: () => import('@/views/vendor/PromotionForm.vue'),
            },
            {
              path: '/promotionform/:slug',
              name: 'Formulaire promotions',
              meta: { auth: true },
              component: () => import('@/views/vendor/PromotionForm.vue'),
            },
            {
              path: '/products',
              name: 'Produits',
              meta: { auth: true },
              component: () => import('@/views/Product/ProductList.vue'),
            },
            {
              path: '/stock-management',
              name: 'Gestion du stock',
              meta: { auth: true },
              component: () => import('@/views/Stocks/StockManagement.vue'),
            },
            {
              path: '/stock-logs',
              name: 'Journal de stock',
              meta: { auth: true },
              component: () => import('@/views/Stocks/StockLog.vue'),
            },
            {
              path: '/create-product',
              name: 'CreateProduct',
              meta: { auth: true },
              component: () => import('@/views/Product/CreateProduct.vue'),
            },
            {
              path: '/create-product/:slug',
              name: 'EditProduct',
              meta: { auth: true },
              component: () => import('@/views/Product/CreateProduct.vue'),
            },
            {
              path: '/create-simple-product',
              name: 'CreateProduct',
              meta: { auth: true },
              component: () => import('@/views/Product/CreateSimpleProduct.vue'),
            },
            {
              path: '/create-simple-product/:slug',
              name: 'EditProductSimple',
              meta: { auth: true },
              component: () => import('@/views/Product/CreateSimpleProduct.vue'),
            },

            /* {
              path: '/seller/packages',
              name: 'VendorOrderList',
              component: () => import('@/views/VendorOrderList.vue'),
            }, */
            {
              path: '/orders',
              name: 'Commandes',
              meta: { auth: true },
              component: () => import('@/views/Orders/OrderList.vue'),
            },
            {
              path: '/orders/:id',
              name: 'Details Commandes',
              meta: { auth: true },
              component: () => import('@/views/Orders/OrderDetails.vue'),
            },
            {
              path: '/packages/:id',
              name: 'Details Colis',
              meta: { auth: true },
              component: () => import('@/views/Orders/PackageDetails.vue'),
            },
            {
              path: '/user/packages',
              name: 'Colis à Recevoir',
              component: () => import('@/views/Orders/PackageList.vue'),
            },
            {
              path: '/seller/packages',
              name: 'Colis à expédier',
              meta: { auth: true },
              component: () => import('@/views/Orders/PackageList.vue'),
            },
            {
              path: '/offers',
              name: 'Offres',
              meta: { auth: true },
              component: () => import('@/views/Orders/OfferList.vue'),
            },
          ],
        },
        {
          path: '/home-two',
          name: 'Home Two',
          component: () => import('@/views/home/HomeTwo.vue'),
        },
        {
          path: '/home-three',
          name: 'Home Three',
          component: () => import('@/views/home/HomeThree.vue'),
        },
        {
          path: '/home-four',
          name: 'Home Four',
          component: () => import('@/views/home/HomeFour.vue'),
        },
        {
          path: '/components',
          name: 'Components',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: '/categories/:slug',
          name: i18n.t('Category Page'),
          component: () => import('@/views/shop/SearchProduct.vue'),
        },
        {
          path: '/promotions/:slug',
          name: i18n.t('Promotions'),
          component: () => import('@/views/shop/SearchProduct.vue'),
        },
        {
          path: '/flashsales',
          name: i18n.t('Flashsales'),
          component: () => import('@/views/shop/Flashsales.vue'),
        },
        {
          path: '/sale-page-one',
          name: 'SalePageOne',
          component: () => import('@/views/pages/SalePageOne.vue'),
        },
        {
          path: '/sale-page-two',
          name: 'SalePageTwo',
          component: () => import('@/views/pages/SalePageTwo.vue'),
        },
        {
          path: '/all-vendors',
          name: 'AllVendors',
          component: () => import('@/views/vendor/AllVendors.vue'),
        },
        {
          path: '/vendor-store',
          name: 'VendorStore',
          component: () => import('@/views/vendor/VendorStore.vue'),
        },
        {
          path: '/search-product',
          name: 'SearchProduct',
          component: () => import('@/views/shop/SearchProduct.vue'),
        },
        {
          path: '/search',
          name: i18n.t('Recherche'),
          component: () => import('@/views/shop/SearchProduct.vue'),
        },
        {
          path: '/single-product',
          name: i18n.t('Produit'),
          component: () => import('@/views/shop/SingleProduct.vue'),
        },
        {
          path: '/products/:slug',
          name: i18n.t('Produit'),
          component: () => import('@/views/shop/SingleProduct.vue'),
        },
        {
          path: '/shops/:slug',
          name: i18n.t('Boutique'),
          component: () => import('@/views/vendor/Vendor.vue'),
        },
        {
          path: '/shops',
          name: i18n.t('Boutiques'),
          component: () => import('@/views/vendor/AllVendors.vue'),
        },
        {
          path: '/cart',
          name: 'Panier',
          component: () => import('@/views/Orders/Cart.vue'),
        },
        {
          path: '/checkout/:id',
          name: 'Cart',
          component: () => import('@/views/Orders/CheckoutLoader.vue'),
        },
        {
          path: '/checkout',
          name: 'Commander',
          component: () => import('@/views/Orders/Checkout.vue'),
        },
        {
          path: '/contact',
          name: 'Contact',
          component: () => import('@/views/Contact.vue'),
        },
        {
          path: '/checkout-alternative',
          name: 'CheckoutAlternative',
          component: () => import('@/views/shop/CheckoutAlternative.vue'),
        },

        {
          path: '/payments/:id',
          name: 'Payment',
          component: () => import('@/views/Orders/Payment.vue'),
        },
        {
          path: '/list-address',
          name: 'ListAddress',
          component: () => import('@/views/address/ListAddress.vue'),
        },
        {
          path: '/add-address',
          name: 'AddAddress',
          component: () => import('@/views/address/AddAddress.vue'),
        },

       /* {
          path: '/profile',
          name: 'Profile',
          component: () => import('@/views/Profile.vue'),
        }, */
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import('@/views/Dashboard.vue'),
        },
        {
          path: '/faq/:slug',
          name: 'Faq',
          component: () => import('@/views/blogs/Faq.vue'),
        },
        {
          path: '/info/:slug',
          name: 'Post',
          component: () => import('@/views/blogs/Post.vue'),
        },
        {
          path: '/about-us',
          name: 'Post',
          component: () => import('@/views/blogs/About.vue'),
        },

        {
          path: '/test',
          name: 'Test',
          component: () => import('@/views/test'),
        },
        {
          path: '/test-2',
          name: 'Test 2',
          component: () => import('@/views/test-2'),
        },

      ],
    },

    {
      path: '/verify_email',
      name: i18n.t('Vérifier l\'adresse électronique'),
      component: () => import('@/views/VerifyEmail.vue'),
    },
    {
      path: '/sign-up',
      name: i18n.t('Sign Up'),
      component: () => import('@/views/SignUp.vue'),
    },
    {
      path: '/login',
      name: i18n.t('Login'),
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/forgout-password',
      name: 'Forgot Password',
      component: () => import('@/views/ForgotPassword.vue'),
    },
    {
      path: '/reset-password',
      name: i18n.t('Reset Password'),
      component: () => import('@/views/ResetPassword.vue'),
    },
    {
      path: '/reset-email',
      name: i18n.t('Reset Email'),
      component: () => import('@/views/profile/ResetEmail.vue'),
    },
    {
      path: '/landing',
      name: 'Landing',
      component: () => import('@/views/Landing.vue'),
    },
    {
      path: '/mobile-categories',
      name: 'Mobile Categories',
      component: () => import('@/views/MobileCategories.vue'),
    },
  ],
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)
export default router
