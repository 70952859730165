// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app'
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics'

import { getMessaging } from 'firebase/messaging/sw'

const firebaseConfig = {
  apiKey: 'AIzaSyCZTYeh28o57cuIdFh-z81VZI7vlRlhfEo',
  authDomain: 'planete-market-84c3a.firebaseapp.com',
  projectId: 'planete-market-84c3a',
  storageBucket: 'planete-market-84c3a.appspot.com',
  messagingSenderId: '560541178019',
  appId: '1:560541178019:web:e4736fdd20e24a7072c716',
  measurementId: 'G-4D7KX6FLRC',
}

// Initialize Firebase
const firebase = initializeApp(firebaseConfig)
const messaging = getMessaging(firebase)

window.firebase = firebase
window.messaging = messaging
